import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Image from "../../components/image"

export default function IndexPage(getData) {

    function setValue(type, data) {
      let result = ''
      switch (type) {
        case 'fontType':
          result = (data === '欧文' ? 'tl-roman' : 'tl-japanese')
          break;

        case 'aos':
          result = (data%2 !== 0 ? 'fade-left' : 'fade-right')
          break;

        default:
          break;
      }
      return result
    }

    return (
      <Layout>
        <SEO title="書体年表" />
        <main className="w-full pt-8 sm:pt-32 pb-8">
          <div className="l-timeline">
            {getData.data.allTimelineJson.edges.map((item, idx) =>
              <div
                key={item.node.id}
                className="tl-block max-w-3xl"
              >
                <div
                  className={`tl-year ${setValue('fontType', item.node.type)}`}
                  data-aos="zoom-in"
                  data-aos-offset="300"
                  data-aos-once="true"
                >
                  {item.node.announcement_year}
                </div>
                <div className="tl-content-wrap">
                  <div
                    className="tl-content"
                    data-aos={setValue('aos', idx)}
                    data-aos-offset="300"
                    data-aos-once="true"

                  >
                    <h2 className="name">
                      {item.node.name}
                      {item.node.name_kana && (
                        <small>（{item.node.name_kana}）</small>
                      )}
                    </h2>
                    {item.node.description && (
                      <p className="description" dangerouslySetInnerHTML={{
                        __html: item.node.description.replace(/\n/g, '<br />')
                      }}/>
                    )}
                    {item.node.credit && (
                      <p className="credit">開発：{item.node.credit}</p>
                    )}
                    {item.node.cite && (
                      <p className="cite">
                        <cite dangerouslySetInnerHTML={{
                        __html: item.node.cite.replace(/\n/g, '<br />')
                        }}/>
                      </p>
                    )}
                    {item.node.filename && (
                      <p className="image"><Image filepath={`timeline/${item.node.filename}.svg`} alt={item.node.name} /></p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </Layout>
    )
}

export const query = graphql`
  query {
    allTimelineJson(sort: {fields: announcement_year}, filter: {status: {eq: "publish"}}) {
      edges {
        node {
          id
          announcement_year
          type
          name
          name_kana
          description
          credit
          cite
          filename
        }
      }
    }
  }
`
